import { Modal, ModalProps } from 'react-bootstrap';
import React, { createContext, LazyExoticComponent, ReactNode, Suspense, useContext, useEffect, useRef, useState } from 'react';
import { modals, ModalsIdsType } from 'components/modal/list';
import { CloseCircleIcon } from 'components/icons';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Callback } from '@types';
import SpinnerLoader from 'components/loader/spinner';

import style from './style.module.scss';
import { useUser } from 'hooks/api/user';

type ContextProps = {
  modalName?: string;
  open: (modalId: ModalsIdsType, options?: ModalOptions) => void;
  hide: () => void;
  isOpen: boolean;
  isHideable: boolean;
  previousSearchParams: URLSearchParams | null;
  hideable: (value: boolean) => void;
  refOnExited: any;
  refModalContainer: HTMLDivElement | null;
  onExited: (cb: Callback | undefined) => void;
  setPreviousSearchParams: (params: URLSearchParams) => void;
};

const defaultContextProps: ContextProps = {
  open: () => null,
  hide: () => null,
  isOpen: false,
  isHideable: false,
  previousSearchParams: null,
  hideable: () => null,
  refOnExited: null,
  refModalContainer: null,
  onExited: cb => null,
  setPreviousSearchParams: p => null
};
export const ModalContext = createContext<ContextProps>(defaultContextProps);

export type ModalSize = 'lg' | 'sm' | 'xl' | 'md' | undefined;
const defaultSize = 'lg';

export type ModalHeight = 'auto' | 'h-360' | 'h-540' | 'h-720' | 'h-600';
const defaultHeight = 'auto';

interface ModalOptions {
  isError?: boolean;
  isHideable?: boolean;
  replace?: boolean;
  size?: ModalSize;
  height?: ModalHeight;
  props?: {};
  queryProps?: {};
  keepQueryProps?: boolean;
  onHide?: Callback;
}
interface Props {
  children: ReactNode;
}
export default function CustomModal({ children }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: userData } = useUser();
  const [replace, setReplace] = useState(false);
  const [show, setShow] = useState(false);
  const [hideable, setHideable] = useState(true);
  const [props, setProps] = useState({});
  const [contentClassName, setContentClassName] = useState('');
  const [size, setSize] = useState<ModalSize>(defaultSize);
  const [height, setHeight] = useState<ModalHeight>(defaultHeight);
  // const [isError, setIsError] = useState<boolean>(false);
  const [CurrentComponent, setCurrentComponent] = useState<LazyExoticComponent<any> | null>(null);
  const refOnExited = useRef<Callback | undefined>(undefined);
  const refOnHide = useRef<Callback | undefined>(undefined);
  const refOldSearchParams = useRef<URLSearchParams | null>(null); // Used to keep the old url search params on the URL.
  const [searchParams] = useSearchParams();
  const modalParam = searchParams.get('modal');
  const refModalContainer = useRef<HTMLDivElement | null>(null);

  /**
   * It has to check if it is the first access on app to allow/disallow modals with dependencies.
   */
  const refFirstAccess = useRef(true);

  const open = (modalId: ModalsIdsType, options: ModalOptions = {}) => {
    console.log('Modal open function:', modalId, options);
    if (modals[modalId].hasDependencies && refFirstAccess.current) {
      console.log('Redirecting: modal not allowed.', location);
      navigate(location.pathname, { replace: true });
    } else {
      const { props, queryProps, size, height, keepQueryProps = true, replace, isHideable, onHide } = options;
      const oldQueryProps = keepQueryProps ? Object.fromEntries(searchParams) : {};
      const query = new URLSearchParams({ ...oldQueryProps, ...queryProps, modal: modalId });

      if (onHide) refOnHide.current = onHide;

      // setSearchParams(query.toString());
      setProps(props || {});
      // setIsError(options.isError || false);
      setSize(size || defaultSize);
      setHeight(height || defaultHeight);
      setReplace(replace ?? false);
      setHideable(isHideable ?? true);

      navigate('?' + query.toString(), { replace: Boolean(replace) });
    }
  };

  const locationSearch = location.search;
  const locationPathname = location.pathname;

  useEffect(() => {
    const params = new URLSearchParams(locationSearch);
    // Keeping reference for URL search params before the modal is being opened.
    // This is necessary because we have to send the user back to the previous pathname with the URL's params.
    if (params.get('modal') === null) {
      refOldSearchParams.current = params;
    }

    const userInfo = userData?.userInfo;
    if (modalParam != null && userInfo) {
      const modal = modals[modalParam as ModalsIdsType];

      if (!modal.allowPermissions || (modal.allowPermissions && modal.allowPermissions.includes(userInfo.permission))) {
        if (modal.hasDependencies && refFirstAccess.current) {
          console.log('Redirecting: modal not allowed.', locationPathname, locationSearch);
          params.delete('modal');
          navigate(locationPathname + '?' + params.toString(), { replace: true });
        } else {
          console.log('Opening modal:', modalParam, props, locationSearch, locationPathname, userData);

          setShow(true);

          // We need to force the size type because bootstrap's types are wrong.
          // It says that 'md' doesn't exist, but it does.
          if (modal.size) setSize(modal.size as ModalProps['size']);

          if (modal.contentClassName) setContentClassName(modal.contentClassName);

          if (modal.height) setHeight(modal.height);

          setCurrentComponent(modal.component);
        }
      } else navigate(-1);
    } else {
      setShow(false);
    }

    refFirstAccess.current = false;

    return () => {
      // refFirstAccess.current = true;
    };
  }, [modalParam, navigate, props, locationSearch, locationPathname, userData]);

  const hide = () => {
    console.log('hide function');
    if (refOnHide.current) refOnHide.current();

    setShow(false);

    refOnHide.current = undefined;
  };

  const onHide = (force?: boolean) => {
    // console.log('onHide');
    if (force || hideable) hide();
    // if (hideable) onExited();
  };

  const onSetHideable = (value: boolean) => {
    setHideable(value);
  };

  const onExited = () => {
    // console.log('onExited', { replace, searchParams: searchParams.toString(), modal: modalParam });

    setCurrentComponent(null);
    setProps({});
    // setSearchParams('');
    // setIsError(false);
    setSize(defaultSize);
    setHeight(defaultHeight);
    setContentClassName('');
    // navigate('..', { replace });
    const params = refOldSearchParams.current ? refOldSearchParams.current.toString() : '';
    navigate(`${location.pathname}${params !== '' ? `?${params}` : ''}`, { replace });

    refOnExited.current?.();
    refOnExited.current = undefined;
  };

  const setPreviousSearchParams = (params: URLSearchParams) => {
    refOldSearchParams.current = params;
  };

  return (
    <ModalContext.Provider
      value={{
        modalName: modalParam ?? undefined,
        open,
        hide: onHide,
        isOpen: show,
        isHideable: hideable,
        hideable: onSetHideable,
        refOnExited,
        previousSearchParams: refOldSearchParams.current,
        refModalContainer: refModalContainer.current,
        setPreviousSearchParams,
        onExited: cb => {
          // if (!refOnExited.current) refOnExited.current = cb;
          if (cb) refOnExited.current = cb;
        }
      }}
    >
      {children}
      <div ref={refModalContainer}>
        <Modal
          // @ts-ignore
          size={size}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          scrollable
          contentClassName={`${style.content} ${size} ${height} ${contentClassName}`}
          dialogClassName={style.modal}
          backdrop={'static'}
          onHide={onHide}
          onExited={onExited}
        >
          <Suspense
            fallback={
              <>
                <ModalHeader>Loading...</ModalHeader>
                <SpinnerLoader stretch />
              </>
            }
          >
            {CurrentComponent ? <CurrentComponent {...props} /> : null}
          </Suspense>
        </Modal>
      </div>
    </ModalContext.Provider>
  );
}

interface ModalHeaderProps {
  children: ReactNode;
  isError?: boolean;
  isWarning?: boolean;
}
export function ModalHeader(props: ModalHeaderProps) {
  const { children, isError, isWarning } = props;
  const { hide, isHideable } = useModal();

  let bg = 'bg-primary';
  if (isError) bg = 'bg-danger';
  else if (isWarning) bg = 'bg-warning';

  return (
    <Modal.Header className={`${bg} gap-3`}>
      <Modal.Title id="contained-modal-title-vcenter" className={'text-white fs-3 fw-bold w-100'}>
        {children}
      </Modal.Title>

      {isHideable && (
        <button type={'button'} className={'text-white'} onClick={hide} style={{ marginTop: -1 }}>
          <CloseCircleIcon />
        </button>
      )}
    </Modal.Header>
  );
}

export function createModalLink(modal: ModalsIdsType, queryProps?: Record<string, string | number | null | undefined>, keepOldProps: boolean = true) {
  let oldProps = {};
  if (keepOldProps) oldProps = Object.fromEntries(new URLSearchParams(window.location.search));
  return `?${new URLSearchParams({ ...oldProps, ...queryProps, modal }).toString()}`;
}

export function useModal(onExited?: Callback) {
  const {
    modalName,
    open,
    hide,
    isOpen,
    onExited: onExitedModal,
    isHideable,
    hideable,
    previousSearchParams,
    refModalContainer,
    setPreviousSearchParams
  } = useContext(ModalContext);

  useEffect(() => {
    onExitedModal(onExited);
  }, [onExitedModal, onExited]);

  return {
    modalName,
    setPreviousSearchParams,
    open,
    hide,
    isOpen,
    isHideable,
    hideable,
    previousSearchParams,
    refModalContainer
  };
}
